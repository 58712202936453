<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('编辑', scope.data)"
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";

export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: this.changeRuleForm,
    };
  },
  data() {
    return {
      allProps,
      Api,
    };
  },
  created() {
    /*   this.allProps.items.forEach((item) => {
  
      }); */
  },
  methods: {
    dialogForm(title, row = {}) {
      row.fileList = [{ name: "123", url: this.$mainUrl(row.img) }];
      console.log("row", row);
      allProps.dialog = {
        isShow: true,
        title,
      };
      allProps.data = { ...row };
      console.log(" allProps.data", allProps.data);
    },
    changeTableList(data) {
      data.forEach((item) => {
        item.ename=item.name
      });
      return data;
    },
    changeRuleForm(formData, uploadData) {
        uploadData.ename = formData?.ename;
        uploadData.money = formData?.money;
        uploadData.text = formData?.text;
        uploadData.text2 = formData?.text2;
      if (this.allProps.dialog.title === "编辑") uploadData.id = formData?.id;
      console.log("uploadData", uploadData);
      return uploadData;
    },
    async deleteRow(id) {
      let result = await messageBox(Api.allGame.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
