import { Api } from "@/apis";
const editorOption={
    placeholder: "请在这里输入",
        theme: "snow", //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
}
const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    [{ header: 1 }, { header: 2 }], // 1、2 级标题
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
    [{ script: "sub" }, { script: "super" }], // 上标/下标
    [{ indent: "-1" }, { indent: "+1" }], // 缩进
    // [{'direction': 'rtl'}],                         // 文本方向
    [{ size: ["small", false, "large", "huge"] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
    [{ font: [] }], // 字体种类
    [{ align: [] }], // 对齐方式
    ["clean"], // 清除文本格式
    // ['link', 'image', 'video'] // 链接、图片、视频
    ["link", "image", "video"], // 链接、图片
  ];
export const allProps = {
  isShowBtn: {
    addBtn: true,
    dialogFormBtn:true
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: {fileList:[] },
  items: [
    {
      label: "id",
      prop: "id",
      type: ["table"],
    },
    {
      label: "游戏名字",
      prop: "ename",
      type: ["table", "add","edit"],
    },
    {
      label: "游戏价格",
      prop: "money",
      type: ["table", "add","edit"],
    },
    {
        label: "游戏图片",
        prop: "img",
        type: ["table"],
        Ttype: "image",
      },
    {
      label: "游戏图片",
      prop: "fileList",
      type: ["add","edit"],
      Ftype: "upload",
    },
    {
      label: "游戏内容",
      prop: "text",
      type: ["add","edit"],
    //   Ftype: "solt", 
      Ftype: "quillEditor", 
      options: editorOption,
    },
    {
      label: "游戏详情",
      prop: "text2",
      type: ["add","edit"],
      Ftype: "quillEditor",
      options: editorOption,
    },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "220px",
    },
  ],
  api: Api.allGame,
};
